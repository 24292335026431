<template>
  <!--          Эрх сунгалтууд-->
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-white border border-gray-200 rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-parcel"></i>
        Эрх сунгалтууд
      </header>
      <div class="m-3">
        <div
            class="alert alert-info rounded mx-2 py-4 bg-transparent hover:bg-[#333d4a] hover:scale-[102%] transition-all duration-300">
          <div class="flex-1">
            <i class="uil uil-bell text-[#009688] text-2xl pr-2"></i>
            <label>
              <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
              <p class="text-sm text-base-content text-opacity-60">
                Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
                sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
                adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
                dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
                consectetur adip!
              </p>
            </label>
          </div>
        </div>
      </div>
      <div class="m-3">
        <div
            class="alert alert-info rounded mx-2 py-4 bg-transparent hover:bg-[#333d4a] hover:scale-[102%] transition-all duration-300">
          <div class="flex-1">
            <i class="uil uil-bell text-[#009688] text-2xl pr-2"></i>
            <label>
              <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
              <p class="text-sm text-base-content text-opacity-60">
                Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
                sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
                adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
                dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
                consectetur adip!
              </p>
            </label>
          </div>
        </div>
      </div>
      <div class="m-3">
        <div
            class="alert alert-info rounded mx-2 py-4 bg-transparent hover:bg-[#333d4a] hover:scale-[102%] transition-all duration-300">
          <div class="flex-1">
            <i class="uil uil-bell text-[#009688] text-2xl pr-2"></i>
            <label>
              <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
              <p class="text-sm text-base-content text-opacity-60">
                Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
                sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
                adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
                dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
                consectetur adip!
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--    <div class="profile-div">-->
  <!--        <div class="profile-card">-->
  <!--            <div class="normal-header">-->
  <!--                <span class="user-name"><i class="el-icon-collection"></i><span class="title-left">Эрх сунгалтууд</span></span>-->
  <!--            </div>-->
  <!--            <div class="card-content">-->

  <!--                <div v-if="isLoading">-->
  <!--                    <extend-rights-placeholder></extend-rights-placeholder>-->
  <!--                </div>-->
  <!--                <div v-else class="pro-container">-->
  <!--                    <ul>-->
  <!--                        <li v-for="item in episodes" :key="item.index">-->
  <!--                            <a href="https://max.test/home#/profile/viewed-history" class="profile-list-main">-->
  <!--                                <div class="viewed-video-info">-->
  <!--                                    <div class="viewed-video-sub">{{ item.created_at }}</div>-->
  <!--                                    <div class="viewed-video-desc" v-html="item.description"></div>-->
  <!--                                </div>-->
  <!--                            </a>-->
  <!--                        </li>-->
  <!--                    </ul>-->
  <!--                    <div class="load-more-wrap has-text-centered">-->
  <!--                        <loader v-if="isLoadingMore"></loader>-->
  <!--                        <a-->
  <!--                            v-else-->
  <!--                            href="javascript:void(0)"-->
  <!--                            @click="getEpisodes(page, true)"-->
  <!--                            class="load-more-button"-->
  <!--                        >Цааш үзэх</a-->
  <!--                        >-->
  <!--                    </div>-->
  <!--                </div>-->

  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>

// import Loader from "../../components/loader.vue";
// import ExtendRightsPlaceholder from "../../components/placeholders/extend-rights-placeholder.vue";
import {mapActions} from "vuex";
import axios from "axios";

export default {
  name: 'ExtendRights',
  props: ["url", "hlink"],
  components: {
    // Loader,
    // ExtendRightsPlaceholder,
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      page: 1,
      episodes: [],
      toggle: true,
    };
  },

  computed: {},

  created() {
    // this.getEpisodes(1, false);
  },

  methods: {
    ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),

    toggleCheck() {
      this.toggle = !this.toggle;
    },

    getEpisodes(page, isMore) {
      if (this.page == 1) {
        this.isLoading = true;
      }
      if (isMore) {
        this.isLoadingMore = true;
      }

      axios
          // .get(`${this.$props.url}?page=${page}`)
          .get(`https://max.test/api/m/anime/list/2??page=${page}`)
          .then(({data}) => {
            data.data.forEach((item) => {
              this.episodes.push(item);
            });
            this.page = data.current_page + 1;
            this.isLoading = false;
            this.isLoadingMore = false;
          })
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
            this.isLoadingMore = false;
          });
    },

    gotoWatch(id) {
      this.setBackLink(this.$route.path);
      this.$router.push(`/watch/episode/${id}`);
    }
  },
};
</script>
